import React, { useState } from "react";
import "styled-components/macro";
import { useEndpoint } from "../helpers/use-endpoint";
import { Callout, Switch, Tab, Tabs } from "@blueprintjs/core";
import { Spin } from "antd";
// import { ScoresAndShapesTable } from "./ScoresAndShapesTable";
import { NivoLineFromEndpoint } from "./AgTable/nivo-line";
import { useBoolean } from "../helpers/useBoolean";

const DEFAULT_TAB_ID = "facts";
const CTC_HISTORY__ENDPOINT =
  "peopleready/weekly_cumulative_true_churners_history";
const WAC_HISTORY__ENDPOINT = "peopleready/weekly_active_customers_history";
const CCE_HISTORY__ENDPOINT =
  "peopleready/weekly_customer_churn_events_history";
const WCAC_HISTORY__ENDPOINT =
  "peopleready/weekly_cumulative_activated_customers_history";
const TAANTC_HISTORY__ENDPOINT =
  "peopleready/total_activated_and_not_true_churned_history";

export function PulsePage() {
  const [selectedTabId, setSelectedTabId] = useState(DEFAULT_TAB_ID);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  const { loading, data } = useEndpoint("/peopleready/pulse_facts");

  if (loading) return <Spin size="large" />;
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
        css={`
          width: 100%;
          height: 100%;
          margin-top: -15px;
        `}
      >
        <Tab id="facts" title="Facts" panel={<Facts data={data} />} />
        {/*<Tab*/}
        {/*  id="scores_and_shapes"*/}
        {/*  title="Scores & Shapes"*/}
        {/*  panel={<ScoresAndShapesTable />}*/}
        {/*/>*/}
        <Tab
          id="wv_history"
          title="Volume"
          panel={<WeeklyVolumeHistoryLine />}
        />
        <Tab
          id="ac_history"
          title="Active Customers"
          panel={
            <NivoLineFromEndpoint
              endpoint={WAC_HISTORY__ENDPOINT}
              height={600}
              width={1000}
              enableSlices={false}
              enablePoints={false}
            />
          }
        />
        <Tab
          id="cce_history"
          title="Churn Events"
          panel={
            <NivoLineFromEndpoint
              endpoint={CCE_HISTORY__ENDPOINT}
              height={600}
              width={1000}
              enableSlices={false}
              enablePoints={false}
            />
          }
        />
        <Tab
          id="ctc_history"
          title="True Churn"
          panel={
            <NivoLineFromEndpoint
              endpoint={CTC_HISTORY__ENDPOINT}
              height={600}
              width={1000}
              enableSlices={false}
              enablePoints={false}
            />
          }
        />
        <Tab
          id="cac_history"
          title="Cumulative Activated Customers"
          panel={
            <NivoLineFromEndpoint
              endpoint={WCAC_HISTORY__ENDPOINT}
              height={600}
              width={1000}
              enableSlices={false}
              enablePoints={false}
            />
          }
        />
        <Tab
          id="taantc_history"
          title="Activated & Not True-Churned"
          panel={
            <NivoLineFromEndpoint
              endpoint={TAANTC_HISTORY__ENDPOINT}
              height={600}
              width={1000}
              enableSlices={false}
              enablePoints={false}
            />
          }
        />
      </Tabs>
    </div>
  );
}

function WeeklyVolumeHistoryLine() {
  const isCumulative = useBoolean(false);
  const endpointName = isCumulative.value ? "cumulative" : "total";
  const endpoint = `peopleready/${endpointName}_weekly_volume_history`;
  console.log({ endpoint });
  return (
    <div>
      <Switch
        checked={isCumulative.value}
        onChange={() => {
          isCumulative.toggle();
        }}
        label="Cumulative"
        large
        inline={false}
      />
      <NivoLineFromEndpoint
        endpoint={endpoint}
        height={600}
        width={1000}
        enableSlices={false}
        enablePoints={false}
      />
    </div>
  );
}

function Facts({ data }) {
  return (
    <div
      css={`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > * {
          margin: 16px 0;
          width: 31%;
        }

        & .bp3-card.bp3-card {
          padding: 20px;
        }
      `}
    >
      {data.map((d, idx) => (
        <Fact key={idx} {...d} />
      ))}
    </div>
  );
}

function Fact(props) {
  return (
    // <Card elevation={Classes.ELEVATION_2}>
    <Callout intent="primary" icon="pulse" fill={false} title={props.name}>
      {props.content}
    </Callout>
    // </Card>
  );
}
