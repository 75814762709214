function noop() {}

console.log({ "process.env.NODE_ENV": process.env.NODE_ENV });

if (
  (!window.Cypress && process.env.NODE_ENV !== "development") ||
  process.env.NODE_ENV === "production"
) {
  console.log = noop;
}
