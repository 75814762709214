import React from "react";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position
} from "@blueprintjs/core";
import { ChuScoPopoverContent } from "./popover-content";
import { ChuScoPopoverTarget } from "./popover-target";
import "styled-components/macro";

export function ChurnScoreCellRenderer({
  value: churnScore,
  data: { customer_id: customerId }
}) {
  return <ChuScoPopover churnScore={churnScore} customerId={customerId} />;
}

function ChuScoPopover({ churnScore, customerId }) {
  return (
    <Popover
      content={
        <ChuScoPopoverContent churnScore={churnScore} customerId={customerId} />
      }
      position={Position.RIGHT_TOP}
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName={`${Classes.ELEVATION_4}`}
      hoverOpenDelay={300}
      fill={true}
    >
      <ChuScoPopoverTarget churnScore={churnScore} />
    </Popover>
  );
}
