import { Icon as BP3Icon, Intent, ProgressBar } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components/macro";
import { Col, Row } from "antd";
import { getScoreInfo } from "../../../helpers/get-score-info";
import { PopConHeader } from "./popover-content";
import { useCustomerRow } from "../../../helpers/use-customer-row";

const YesChurnIcon = () => <BP3Icon icon="warning-sign" intent="danger" />;
const NoChurnIcon = () => <BP3Icon icon="shield" intent="success" />;
const ScoFacExplanation = styled.div`
  margin-bottom: 8px;
`;

function ScoFacIcon({ contrib }) {
  return contrib > 0 ? <YesChurnIcon /> : <NoChurnIcon />;
}

function ScoFacBar({ contrib, maxContrib }) {
  return (
    <ProgressBar
      animate={false}
      stripes={false}
      intent={contrib < 0 ? Intent.SUCCESS : Intent.DANGER}
      value={Math.abs(contrib / maxContrib)}
    />
  );
}

function ScoreFactor({ name, contrib, valueExplanation, maxContrib }) {
  return (
    <Row
      className="feature-contribution-row"
      key={name}
      type="flex"
      justify="space-between"
      align="middle"
      gutter={16}
    >
      <Col>
        <ScoFacIcon contrib={contrib} />
      </Col>
      <Col
        css={`
          flex-grow: 1;
        `}
      >
        <ScoFacExplanation>{valueExplanation}</ScoFacExplanation>
        <ScoFacBar contrib={contrib} maxContrib={maxContrib} />
      </Col>
    </Row>
  );
}

const ScoFacWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > .feature-contribution-row {
    margin: 24px 0 0 0 !important;
  }
  & > .feature-contribution-row:first-child {
    margin-top: 0 !important;
  }
`;

export function ScoreFactors({ customerId }) {
  const customerRow = useCustomerRow(customerId);
  const scoreInfo = getScoreInfo(customerRow);
  const maxContrib = scoreInfo[0].contrib;
  const top3_scoreInfo = scoreInfo.filter(
    ({ valueExplanation }) => !!valueExplanation
  );
  return (
    <ScoFacWrapper>
      {/*<PopConHeader>Score Factors</PopConHeader>*/}
      {top3_scoreInfo.map((info, idx) => (
        <ScoreFactor key={idx} maxContrib={maxContrib} {...info} />
      ))}
    </ScoFacWrapper>
  );
}
