import React from "react";
import { integerVF, moneyVF } from "../gridOptions";
import _ from "lodash";
import { Tag } from "@blueprintjs/core";

export function ThePopoverTarget({ volume_last_week }) {
  if (
    _.isUndefined(volume_last_week) ||
    _.isNull(volume_last_week) ||
    _.isNaN(volume_last_week)
  ) {
    return <span />;
  } else {
    const content = moneyVF(volume_last_week);
    return (
      <Tag intent="primary" interactive large minimal>
        {content}
      </Tag>
    );
  }
}
