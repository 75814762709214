import React, { useMemo } from "react";
import { Callout } from "@blueprintjs/core";
import "styled-components/macro";

export function HelpText({ numVisits }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getHelpText(numVisits), []);
}

function getHelpText(numVisits) {
  if (numVisits === 0) {
    return <span> </span>;
  } else {
    return (
      <Callout
        intent="warning"
        title="Trouble logging in?"
        css={`
          margin-top: 48px;
        `}
      >
        Please make sure you are using the correct email and password
        combination, which should have been sent to you by an administrator
        before you first visited this portal. If this issue persists, please
        email team@saxecap.com for additional assistance.
      </Callout>
    );
  }
}
