import React from "react";
import "styled-components/macro";
import _ from "lodash";

export function calculateTotal(data) {
  if (_.isArray(data)) {
    return data.map(calculateTotal);
  } else if (_.isArray(data.children)) {
    return {
      id: data.id,
      total: _.sumBy(calculateTotal(data.children), "total")
    };
  } else {
    return { id: data.id, total: data.value };
  }
}
