import { Computed, computed, reducer, Thunk, thunk } from "easy-peasy";
import { fbReducer } from "../fbase";
import { message } from "antd";
import { getFirebase } from "react-redux-firebase";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";

export interface EmailPassword {
  email: string;
  password: string;
}

export interface FirebaseModel {
  red: any;
  firebaseState: Computed<FirebaseModel, any, StoreModel>;
  logout: Thunk<FirebaseModel, void, Injections, StoreModel>;
  loginEmailPassword: Thunk<
    FirebaseModel,
    EmailPassword,
    Injections,
    StoreModel
  >;
  handlePasswordReset: Thunk<FirebaseModel, string, Injections, StoreModel>;
}

// @ts-ignore
export const firebaseModel: FirebaseModel = {
  red: reducer(fbReducer),
  firebaseState: computed([(state) => state.red], (red) => {
    if (!red) {
      return null;
    }
    const fb = red.firebase;
    if (!fb) {
      return null;
    }
    return fb;
  }),
  logout: thunk(async (actions, payload, { getState }) => {
    await getFirebase().logout();
  }),
  handlePasswordReset: thunk(async (actions, email) => {
    getFirebase().resetPassword(email);
  }),
  loginEmailPassword: thunk(
    async (actions, { email, password }, { getState, getStoreState }) => {
      const fb = getFirebase();
      try {
        const result = await fb
          .auth()
          .signInWithEmailAndPassword(email, password);
        message.success(`Successfully signed in ${email}`);
        console.log({ result });
      } catch (err) {
        console.log({ err });
        if (err.code === "auth/user-not-found") {
          message.error(
            `Failed to sign in. Error: Unregistered Email. ` +
              `Please email team@saxecap.com to request Customax access for this email address.`,
            5
          );
        } else if (err.code === "auth/wrong-password") {
          message.error(
            `Failed to sign in. Error: Incorrect Password. Please use the password ` +
              `issued to you by an administrator.`,
            5
          );
        } else {
          message.error(`Failed to sign in. Error: ${err}`);
        }
      }
    }
  ),
};
