import { FormGroup } from "@blueprintjs/core";
import "styled-components/macro";
import React from "react";

function opacityFn({ activeField, alwaysShowHelperText, labelFor }) {
  return alwaysShowHelperText || activeField === labelFor ? 1 : 0;
}

export function StyledFormGroup({ label, labelFor, children, ...restProps }) {
  return (
    <FormGroup
      css={`
        & .bp3-form-helper-text {
          opacity: ${opacityFn};
          transition: opacity 0.4s ease-in;
        }
      `}
      label={
        <span data-testid={`form_group_label_for_${labelFor}`}>{label}</span>
      }
      labelFor={labelFor}
      {...restProps}
    >
      {children}
    </FormGroup>
  );
}
