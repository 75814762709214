import _ from "lodash";

const FEAT_CONTRIB_FIELD_PATT = /^C:\s(?<featname>.+)$/;
const FEAT_VALUE_FIELD_PATT = /^X:\s(?<featname>.+)$/;
const FEAT_EXPLA_FIELD_PATT = /^E:\s(?<featname>.+)$/;
const SCORE_INFO_FIELD_PATT = /^[CXE]:\s(?<featname>.+)$/;

function getFieldType(field) {
  if (FEAT_CONTRIB_FIELD_PATT.exec(field)) {
    return "contrib";
  } else if (FEAT_VALUE_FIELD_PATT.exec(field)) {
    return "value";
  } else if (FEAT_EXPLA_FIELD_PATT.exec(field)) {
    return "expla";
  } else {
    return null;
  }
}

function getFeatureName(field) {
  return SCORE_INFO_FIELD_PATT.exec(field).groups.featname;
}

export function getScoreInfo(customerRow) {
  const featureRows = {};
  Object.entries(customerRow)
    .filter(([field]) => !!getFieldType(field))
    .map(([field, featureValue]) => ({
      featureName: getFeatureName(field),
      fieldType: getFieldType(field),
      featureValue,
    }))
    .forEach(({ featureName, fieldType, featureValue }) => {
      featureRows[featureName] = {
        [fieldType]: featureValue,
        ...(featureRows[featureName] || {}),
      };
    });

  const scoreInfo = Object.entries(featureRows).map(
    ([name, { contrib, value, expla }]) => ({
      name,
      value,
      contrib,
      valueExplanation: expla,
    })
  );
  return _.sortBy(scoreInfo, ({ contrib }) => -Math.abs(contrib));
}
