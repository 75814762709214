const ROUTES = {
  churn_demo: "/churn-dashboard/:demoCodeName",
  signin: "/signin",
  profile: "/profile",
  rollup: "/rollup",
  pulse: "/pulse",
  root: "/",
  DEFAULT: "/churn-dashboard/a2mac1",
};
export default ROUTES;
