import { useParams } from "react-router";

export function useDemoCodeName() {
  const { demoCodeName } = useParams();
  return demoCodeName;
}

export function useDemoModelName() {
  const { demoCodeName } = useParams();
  if (["complysci", "qless", "peopleready"].includes(demoCodeName)) {
    return demoCodeName;
  } else {
    return "anyprospect";
  }
}
