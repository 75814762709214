import React from "react";
import { Icon, Tag } from "@blueprintjs/core";
import { useClipboard } from "use-clipboard-copy";
import "styled-components/macro";

export function PhoneActionButtonCellRenderer({ data }) {
  const { customer_contact_phone } = data;
  return (
    <span>
      <CopyablePhoneTag customer_contact_phone={customer_contact_phone} />
    </span>
  );
}

export function CopyablePhoneTag({ customer_contact_phone }) {
  const clipboard = useClipboard({ copiedTimeout: 500 });
  const onClick = () => clipboard.copy(customer_contact_phone);
  return (
    <Tag
      css={`
        height: 26px;
        & .bp3-icon {
          margin-right: 6px;
        }
      `}
      fill={true}
      minimal={true}
      intent={"primary"}
      large={false}
      icon="phone"
      interactive={true}
      onClick={onClick}
      // rightIcon={<Icon icon="clipboard" onClick={onClick} />}
    >
      {clipboard.copied ? "Copied!" : customer_contact_phone}
    </Tag>
  );
}
