import routes from "./routes";
import { useHistory } from "react-router";
import React, { useCallback } from "react";
import { Icon, Menu } from "antd";
import { Icon as BP3Icon } from "@blueprintjs/core";

export const leftNavMenuItems = [
  {
    urlPath: "churn-dashboard/a2mac1",
    title: "Churn Dashboard",
    bp3IconType: "dashboard",
  },
  {
    urlPath: routes.rollup,
    title: "Rollup",
    bp3IconType: "grid-view",
  },
  {
    urlPath: routes.pulse,
    title: "Pulse",
    bp3IconType: "pulse",
  },
  {
    urlPath: null,
    title: "Customer Trends",
    bp3IconType: "chart",
  },
  // {
  //   urlPath: null,
  //   title: "Product Insights",
  //   bp3IconType: "signal-search"
  // },
  {
    urlPath: null,
    title: "Action Editor",
    bp3IconType: "flow-branch",
  },
  {
    urlPath: null,
    title: "Time Machine",
    bp3IconType: "history",
  },
  {
    urlPath: null,
    title: "Data Sources",
    bp3IconType: "database",
  },
  {
    urlPath: null,
    title: "Admin Settings",
    bp3IconType: "settings",
  },
];

export function LeftNavMenuItem({ urlPath, title, iconType, bp3IconType }) {
  const history = useHistory();
  const onClick = useCallback(() => urlPath && history.push(urlPath), [
    history,
    urlPath,
  ]);
  return (
    <Menu.Item
      key={urlPath || iconType || bp3IconType}
      title={title}
      onClick={onClick}
      data-testid={`leftnav-${urlPath}`}
    >
      {iconType ? <Icon type={iconType} /> : <BP3Icon icon={bp3IconType} />}
    </Menu.Item>
  );
}
