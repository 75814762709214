import {
  action,
  Action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { StoreModel } from "./model";
import {
  makeAuthorizedGetRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import _ from "lodash";

import moment from "moment";
import { Injections } from "./store-injections";
import {
  getScoreHistories,
  InitialData,
  initialInitialData,
  Point,
} from "./common";

const CONSTANTS = {
  model_name: "qless",
  endpoints: {
    email_templates: "qless_email_templates",
    customers_demo_data: "qless_customers",
  },
};

export interface RowDatum {
  customer_id: string;
  [index: string]: any;
}

export interface ActionEmailTemplate {
  action_name: string;
  subject: string;
  body: string;
}

export interface QlessModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: InitialData;
  initialDataReceived: boolean;
  initialDataLoading: boolean;
  data_refreshed_at: string;
  onLogout: ActionOn<QlessModel, StoreModel>;
  receiveDataRefreshedAt: Action<QlessModel, string>;
  onLogin: ThunkOn<QlessModel, Injections, StoreModel>;
  onInitialDataReceived: ThunkOn<QlessModel, Injections, StoreModel>;
  receiveInitialData: Action<QlessModel, InitialData>;
  markInitialDataReceived: Action<QlessModel, void>;
  maybeHandleFetchInitialData: Thunk<QlessModel, void, Injections, StoreModel>;
  handleFetchInitialData: Thunk<QlessModel, void, Injections, StoreModel>;
  customer2data: Computed<QlessModel, { [customerId: string]: RowDatum }>;
  actionName2emailTemplate: Computed<
    QlessModel,
    { [actionName: string]: ActionEmailTemplate }
  >;
  rowData: Computed<QlessModel, RowDatum[]>;
  averageChurnScore: Computed<QlessModel, number>;
  scoreHistoryDataByCustomerId: Computed<
    QlessModel,
    (customerId: string) => { id: string; data: Point[] }
  >;
}

const qlessModel: QlessModel = {
  INITIAL_DATA_ENDPOINT: CONSTANTS.endpoints.customers_demo_data,
  NAME: CONSTANTS.model_name,
  initialData: initialInitialData(),
  initialDataReceived: false,
  initialDataLoading: false,
  data_refreshed_at: "",
  receiveDataRefreshedAt: action((state, data_refreshed_at) => {
    state.data_refreshed_at = data_refreshed_at;
  }),
  onLogin: thunkOn(
    (actions, storeActions) => storeActions.me.receiveInitialData,
    async (actions, __, { getState }) => {
      if (!getState().data_refreshed_at) {
        const data_refreshed_at = moment
          .utc()
          .subtract(1.2, "hours")
          .toISOString();
        actions.receiveDataRefreshedAt(data_refreshed_at);
      }
    }
  ),
  onLogout: actionOn(
    (__, { me }) => me.clear,
    (state, target) => {
      state.initialData = initialInitialData();
      state.initialDataReceived = false;
      state.initialDataLoading = false;
      state.data_refreshed_at = "";
    }
  ),
  onInitialDataReceived: thunkOn(
    (actions) => actions.markInitialDataReceived,
    async (actions, __, { getState }) => {
      const resp = await makeAuthorizedGetRequestToBackend({
        url: makeUrl(CONSTANTS.endpoints.email_templates),
      });
      actions.receiveInitialData(resp.data);
    }
  ),
  receiveInitialData: action((state, payload) => {
    state.initialData = { ...state.initialData, ...payload };
  }),
  maybeHandleFetchInitialData: undefined,
  handleFetchInitialData: thunk(async (actions, payload, { getState }) => {
    const { INITIAL_DATA_ENDPOINT } = getState();

    const resp = await makeAuthorizedGetRequestToBackend({
      url: makeUrl(INITIAL_DATA_ENDPOINT),
    });
    const { data } = resp;
    data.scoreHistories = getScoreHistories(data.rowData);
    actions.receiveInitialData(data);
    actions.markInitialDataReceived();
  }),
  markInitialDataReceived: undefined,
  rowData: computed(
    [
      (state) => state.initialData.rowData,
      // @ts-ignore
      (state, storeState) => storeState.stars.starsSet,
    ],
    // rowData =>
    (rowData, starsSet) =>
      rowData.map((row) => ({
        ...row,
        id: row.customer_id,
        starred: starsSet && starsSet.has(row.customer_id) ? 1 : 0,
      }))
  ),
  customer2data: computed((state) => {
    const { rowData } = state.initialData;
    const obj = {};

    rowData.forEach((d) => {
      obj[d.customer_id] = { ...d };
    });
    return obj;
  }),
  actionName2emailTemplate: computed((state) => {
    const { emailTemplates } = state.initialData;
    const obj = {};

    emailTemplates.forEach((d) => {
      obj[d.action_name] = { ...d };
    });
    return obj;
  }),

  scoreHistoryDataByCustomerId: computed(
    [(s) => s.initialData.scoreHistories],
    (scoreHistories) => (customerId) => {
      return scoreHistories.filter(({ id }) => id === customerId)[0];
    }
  ),

  // TODO: Ensure this is memoized
  averageChurnScore: computed(
    [(state) => state.initialData.rowData],
    (rowData) => {
      return _.sumBy(rowData, "y_churn_risk_score") / rowData.length;
    }
  ),
};

export default qlessModel;
