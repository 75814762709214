import "styled-components/macro";
import { css } from "styled-components";

export const VERTICALLY_CENTER_IE11 = css`
  &&& {
    position: relative;
  }

  &&& > * {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;
