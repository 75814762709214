import useRedirect from "../hooks/useRedirect";
import { useMe } from "../hooks/useMe";
import routes from "./routes";
import { useMemo } from "react";
import { UserRole } from "./user-role";

interface AuthorizationState {
  loading: boolean;
  isAuthorized: boolean;
}
interface MeData {
  role: UserRole;
  email: string;
}

const SAXECAP_DEV_ROUTES_PATT = RegExp("^/.*$");
const ADMIN_ROUTES_PATT = SAXECAP_DEV_ROUTES_PATT;
const EMPLOYEE_ROUTES_PATT = SAXECAP_DEV_ROUTES_PATT;
const ALLOWED_ROUTES_PATTS = {
  [UserRole.SAXECAP_DEV]: SAXECAP_DEV_ROUTES_PATT,
  [UserRole.ADMIN_READ_WRITE]: ADMIN_ROUTES_PATT,
  [UserRole.ADMIN_READ_ONLY]: ADMIN_ROUTES_PATT,
  [UserRole.DEFAULT]: EMPLOYEE_ROUTES_PATT,
};

export function allowedRoutesForRole(role: UserRole) {
  return ALLOWED_ROUTES_PATTS[role];
}

const amIAuthorized = (meData: MeData, route: string): boolean => {
  const allowedPatt = allowedRoutesForRole(meData.role);
  return allowedPatt.test(route);
};

export function useIsAuthorized(route: string): AuthorizationState {
  const me = useMe();
  const isAuthorized: boolean = me.success
    ? amIAuthorized(me.initialData, route)
    : false;
  return { loading: me.loading, isAuthorized };
}

export function useAuthorizedRoutes(): Set<string> {
  const me = useMe();
  return useMemo(
    () =>
      !me.success
        ? new Set([])
        : new Set(
            Object.values(routes).filter((route) =>
              amIAuthorized(me.initialData, route)
            )
          ),
    [me.initialData, me.success]
  );
}

export function useRedirectIfNotAuthorized(
  protectedRoute: string,
  redirectRoute: string
): boolean {
  const auth = useIsAuthorized(protectedRoute);

  const doneLoading: boolean = !auth.loading;
  const isDefinitelyNotAuthorized: boolean = doneLoading && !auth.isAuthorized;
  const isDefinitelyAuthorized: boolean = doneLoading && auth.isAuthorized;
  useRedirect(() => isDefinitelyNotAuthorized, redirectRoute);
  return isDefinitelyAuthorized;
}
