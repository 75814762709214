import { useEffect } from "react";
import { useHistory } from "react-router";

export default function useRedirect(condition, to, dependencies = []) {
  const history = useHistory();
  useEffect(() => {
    if (condition()) {
      history.push(to);
    }
  }, [condition, history, to, ...dependencies]); // eslint-disable-next-line react-hooks/exhaustive-deps
}
