import { action, createContextStore, persist, thunk } from "easy-peasy";
import "styled-components/macro";
import _ from "lodash";
import { DEFAULT_TILES, giveNames, giveSize } from "./segments";

function model() {
  return persist({
    tiles: DEFAULT_TILES,
    pushTile: action((state, newTile) => {
      newTile = newTile || DEFAULT_TILES[0];
      const maxId = state.tiles.length && _.max(state.tiles.map(t => t.id));
      newTile = { ...giveSize(giveNames(newTile)), id: maxId + 1 };
      state.tiles = [newTile, ...state.tiles];
    }),
    updateTile: action((state, tileUpdates) => {
      state.tiles.forEach(t => {
        if (t.id === tileUpdates.id) {
          if (tileUpdates.axis) t.axis = tileUpdates.axis;
          if (tileUpdates.seg1) t.seg1 = tileUpdates.seg1;
          if (tileUpdates.seg2) t.seg2 = tileUpdates.seg2;
          if (tileUpdates.width > 0) t.width = tileUpdates.width;
          if (tileUpdates.height > 0) t.height = tileUpdates.height;
          if (!_.isUndefined(tileUpdates.ignoreNA))
            t.ignoreNA = tileUpdates.ignoreNA;
          if (!_.isUndefined(tileUpdates.normalizeBarHeights))
            t.normalizeBarHeights = tileUpdates.normalizeBarHeights;
        }
      });
    }),
    toggleTileField: action((state, [id, fieldName]) => {
      state.tiles.forEach(t => {
        if (t.id === id) {
          t[fieldName] = !t[fieldName];
        }
      });
    }),
    clearTiles: action(state => {
      state.tiles = [];
    }),
    duplicateTile: thunk((actions, id, { getState }) => {
      const state = getState();
      const maxId = _.max(state.tiles.map(t => t.id));
      const existing = state.tiles.find(t => t.id === id);
      const newTile = { ...giveSize(giveNames(existing)), id: maxId + 1 };
      state.tiles = [newTile, ...state.tiles];
    }),
    removeTile: action((state, tileId) => {
      state.tiles = state.tiles.filter(tile => tile.id !== tileId);
    })
  });
}

export const TilesStore = createContextStore(model);
// export const useFormStore = createComponentStore(model);
