import COLORS from "./colors";

const MUI_PAPER_BORDER = "none"; //"1px solid transparent";
const MUI_PAPER_BOX_SHADOW =
  "0 1px 2px 0 rgba(60,64,67,0.302), 0 2px 6px 2px rgba(60,64,67,0.149)";

const AG_TABLE_THEMES = {
  light: {
    id: "light",
    primaryColor: COLORS.secondary,
    // ribbon_button_hover_color: COLORS.primary,
    // ribbon_button_active_background_color: "rgba(255, 255, 255, 0.1)",
    ag_grid_theme_name: "ag-theme-balham",
    border: (lightTheme) => `1px solid ${lightTheme.primaryColor}`,
    box_shadow: "none",
    use_scroll_hints: false,
  },
  dark: {
    id: "dark",
    primaryColor: COLORS.primary,
    // ribbon_button_hover_color: COLORS.secondary,
    // ribbon_button_active_background_color: "rgba(0, 0, 0, 0.1)",
    ag_grid_theme_name: "ag-theme-balham-dark",
    border: () => MUI_PAPER_BORDER,
    box_shadow: MUI_PAPER_BOX_SHADOW,
    use_scroll_hints: true,
  },
};

export default AG_TABLE_THEMES;
