import { AutoComplete } from "antd";
import styled from "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import _ from "lodash";
import React, { useMemo } from "react";

const { Option, OptGroup } = AutoComplete;

const OptionContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

function gatherOptions(rowData) {
  const stringCounts = gatherStrings(rowData);
  const sortedStrings = _.sortBy(
    Object.entries(stringCounts),
    ([str, cnt]) => -cnt
  );
  return sortedStrings.map(([str, cnt], idx) => (
    <Option key={idx} value={str} title={" " + _.toLower(str)}>
      <OptionContent>
        {str} <span>{cnt}</span>
      </OptionContent>
    </Option>
  ));
}

function filterOptions(options, iv) {
  const filteredOptions = [];
  options.every(opt => {
    if (iv.length === 0 || filteredOptions.length >= 50) return false;
    const ov = opt.props.title;
    const isMatch = ov.includes(iv) && ov !== iv;
    if (isMatch) {
      filteredOptions.push(opt);
    }
    return true;
  });
  return filteredOptions;
}

export function useOptions(rowData, value) {
  const options = useMemo(() => gatherOptions(rowData), [rowData]);
  const iv = value.length ? " " + _.toLower(_.trim(value)) : "";
  return useMemo(() => filterOptions(options, iv), [options, iv]);
}

function gatherStrings(rowData) {
  if (!rowData || !_.size(rowData)) {
    return [];
  }

  const counts = {};
  rowData.forEach(row => {
    Object.entries(row).forEach(([fieldName, fieldValue]) => {
      if (typeof fieldValue !== "string") return null;
      const s = _.trim(fieldValue);
      if (s.length) {
        counts[s] = (counts[s] || 0) + 1;
      }
      // if (_.isString(fieldValue))
    });
  });
  return counts;
}
