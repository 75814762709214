import { Action, action } from "easy-peasy";
import { useStoreState } from "../hooks/ep";

export interface ModelMisc {
  NAME: string;
  fullScreenModeEnabled: boolean;
  enableFullScreenMode: Action<ModelMisc, void>;
  disableFullScreenMode: Action<ModelMisc, void>;
  toggleFullScreenMode: Action<ModelMisc, void>;
}

const miscModel: ModelMisc = {
  NAME: "misc",
  fullScreenModeEnabled: false,
  enableFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = true;
  }),
  disableFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = false;
  }),
  toggleFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = !state.fullScreenModeEnabled;
  }),
};

export function useFullScreenModeEnabled() {
  return useStoreState((s) => s.misc.fullScreenModeEnabled);
}

export default miscModel;
