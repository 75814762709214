import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "./fbAppConfig";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";

firebase.initializeApp(firebaseConfig);
const fbReducer = combineReducers({
  firebase: firebaseReducer,
});

export { fbReducer, firebase };
