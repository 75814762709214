import { actions } from "./commonActions";
import { thunks } from "./commonThunks";
import { FirebaseModel, firebaseModel } from "./firebaseModel";
import meModel, { MeModel } from "./meModel";
import complysciModel, { ComplysciModel } from "./complysciModel";
import { starsModel } from "./starsModel";
import miscModel, { ModelMisc } from "./model-misc";
import qlessModel, { QlessModel } from "./qlessModel";
import peoplereadyModel, { PeoplereadyModel } from "./peoplereadyModel";
import anyprospectModel, { AnyprospectModel } from "./anyprospectModel";

// The interface representing our entire store model
export interface StoreModel {
  me: MeModel;
  firebase: FirebaseModel;
  stars: any;
  misc: ModelMisc;
  ///////////////
  // Demo Data //
  ///////////////
  complysci: ComplysciModel;
  qless: QlessModel;
  peopleready: PeoplereadyModel;
  anyprospect: AnyprospectModel;
}

const model: StoreModel = {
  me: meModel,
  firebase: firebaseModel,
  stars: starsModel,
  misc: miscModel,
  ///////////////
  // Demo Data //
  ///////////////
  complysci: complysciModel,
  qless: qlessModel,
  peopleready: peoplereadyModel,
  anyprospect: anyprospectModel,
};

function isResourceModel(modelSlice) {
  return !!modelSlice.INITIAL_DATA_ENDPOINT;
}

Object.entries(model).forEach(([modelSliceName, modelSlice]) => {
  if (!isResourceModel(modelSlice)) {
    return;
  }

  modelSlice.initialDataReceived = false;
  modelSlice.initialDataLoading = false;

  const myActions = actions();
  [
    "markInitialDataReceived",
    "markInitialDataNotReceived",
    "markInitialDataLoading",
    "markInitialDataNotLoading",
    "receiveInitialData",
    "clear",
  ].forEach((actionName) => {
    if (!modelSlice[actionName]) {
      modelSlice[actionName] = myActions[actionName];
    }
  });

  const myThunks = thunks(modelSliceName);
  ["handleFetchInitialData", "maybeHandleFetchInitialData"].forEach(
    (thunkName) => {
      if (!modelSlice[thunkName]) {
        modelSlice[thunkName] = myThunks[thunkName];
      }
    }
  );
});

export { model };
