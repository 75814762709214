import React, { useCallback } from "react";
import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import Tooltip from "antd/es/tooltip";
import styled from "styled-components/macro";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import { useRecommendedActionMailtoLink } from "./recommended-action-cell-renderer";
import { CopyablePhoneTag } from "./phone-action-cell-renderer";

function PopoverWrapper({ content, children, className, ...rest }) {
  return (
    <Popover {...rest} content={content} portalClassName={className}>
      {children}
    </Popover>
  );
}

const StyledPopoverWrapper = styled(PopoverWrapper)`
  &&& * {
    z-index: 1000;
  }
`;

export function ActionButtonCellRenderer({ data }) {
  const {
    recommended_action,
    customer_id,
    customer_name,
    customer_contact_phone
  } = data;
  const recommendedActionMailtoLink = useRecommendedActionMailtoLink(data);

  const starsSet = useStoreState(a => a.stars.starsSet);
  const starred = starsSet.has(customer_id);
  const starCustomer = useStoreActions(a => a.stars.starCustomer);
  const unstarCustomer = useStoreActions(a => a.stars.unstarCustomer);

  const onClickStarsAction = useCallback(async () => {
    const storeAction = starred ? unstarCustomer : starCustomer;
    await storeAction({ customerId: customer_id, customerName: customer_name });
  }, [starred, starCustomer, unstarCustomer, customer_id, customer_name]);

  let recommendedActionMenuItem;
  if (recommendedActionMailtoLink === null) {
    recommendedActionMenuItem = null;
  } else {
    recommendedActionMenuItem = (
      <MenuItem
        icon="envelope"
        text={recommended_action}
        onClick={() => window.open(recommendedActionMailtoLink)}
      />
    );
  }

  let phoneMenuItem;
  if (!customer_contact_phone) {
    phoneMenuItem = null;
  } else {
    phoneMenuItem = (
      <MenuItem
        icon={null}
        text={
          <CopyablePhoneTag customer_contact_phone={customer_contact_phone} />
        }
        shouldDismissPopover={false}
      />
    );
  }

  const exampleMenu = (
    <Menu large>
      {phoneMenuItem}
      {recommendedActionMenuItem}
      <MenuItem icon="home" text="View customer in CRM" />
      <MenuItem
        icon="star"
        text={starred ? "Unstar" : "Star"}
        onClick={onClickStarsAction}
      />
    </Menu>
  );
  return (
    <span>
      <StyledPopoverWrapper
        canEscapeKeyClose
        content={exampleMenu}
        position={Position.RIGHT_BOTTOM}
      >
        <Tooltip title="Actions" placement="topLeft" mouseEnterDelay={1.5}>
          <Button
            minimal
            small
            icon="take-action"
            large={false}
            intent="primary"
          />
        </Tooltip>
      </StyledPopoverWrapper>
    </span>
  );
}
