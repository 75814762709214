import { thunk } from "easy-peasy";
import {
  makeAuthorizedGetRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { message } from "antd";

export const thunks = (modelSliceName, getPatchPayload, getPostPayload) => ({
  handleFetchInitialData: thunk(
    async (actions, payload, { getState, dispatch }) => {
      const { INITIAL_DATA_ENDPOINT } = getState();

      const resp = await makeAuthorizedGetRequestToBackend({
        url: makeUrl(INITIAL_DATA_ENDPOINT),
      });
      actions.receiveInitialData(resp.data);
      actions.markInitialDataReceived();
    }
  ),
  maybeHandleFetchInitialData: thunk(
    async (actions, payload, { getState, dispatch }) => {
      const { initialDataLoading, initialDataReceived, NAME } = getState();
      if (!initialDataLoading && !initialDataReceived) {
        actions.markInitialDataLoading();
        try {
          if (!initialDataReceived) {
            await dispatch[NAME].handleFetchInitialData();
          }
        } catch (e) {
          message.config({
            top: 100,
            duration: 5,
            maxCount: 10,
          });
          message.error(
            "Sorry - something went wrong. Try refreshing this page. If this message appears again, please email team@saxecap.com."
          );
          throw e;
        } finally {
          actions.markInitialDataNotLoading();
        }
      }
    }
  ),
});
