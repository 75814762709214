import React from "react";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position
} from "@blueprintjs/core";
import { ThePopoverContent } from "./popover-content";
import { ThePopoverTarget } from "./popover-target";
import "styled-components/macro";
import _ from "lodash";

export function WeeklyVolumeCellRenderer({
  value: volume_last_week,
  data: { customer_id }
}) {
  return (
    <ThePopover volume_last_week={volume_last_week} customer_id={customer_id} />
  );
}

function ThePopover({ volume_last_week, customer_id }) {
  if (
    _.isUndefined(volume_last_week) ||
    _.isNull(volume_last_week) ||
    _.isNaN(volume_last_week)
  ) {
    return <ThePopoverTarget volume_last_week={volume_last_week} />;
  } else {
    return (
      <div
        css={`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Popover
          content={<ThePopoverContent customer_id={customer_id} />}
          position={Position.BOTTOM}
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName={`${Classes.ELEVATION_4}`}
          hoverOpenDelay={300}
          fill={false}
        >
          <ThePopoverTarget volume_last_week={volume_last_week} />
        </Popover>
      </div>
    );
  }
}
