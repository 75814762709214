// eslint-disable-next-line no-unused-vars
var LIGHT_BLUE = "#00acff";
var DARKER_BLUE = "#137CBD";
var DARK_NAVY_BLUE = "#001529";
var NICE_GREEN_BROWN = "#254b42";
var MUSTARD = "#F2B824";

var COLORS = {
  primary: DARKER_BLUE,
  secondary: DARK_NAVY_BLUE,
  //
  light_blue: LIGHT_BLUE,
  darker_blue: DARKER_BLUE,
  dark_navy_blue: DARK_NAVY_BLUE,
  nice_green_brown: NICE_GREEN_BROWN,
  mustard: MUSTARD,
};

module.exports = COLORS;
