import Mustache from "mustache";

function formatBody(bodyTemplate, record) {
  return Mustache.render(bodyTemplate, record);
}

export function record2mailto(emailTemplate, record) {
  const subject = encodeURIComponent(emailTemplate.subject);
  const body = encodeURIComponent(formatBody(emailTemplate.body, record));
  return `mailto:${record.customer_contact_email}?subject=${subject}&body=${body}`;
}
