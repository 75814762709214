import { useStoreActions } from "../hooks/ep";
import { useAsync } from "react-use";
import { useDemoModelName } from "./use-demo-code-name";

export function useEnsureData() {
  const modelName = useDemoModelName();
  const loadInitialData = useStoreActions(
    (a) => a[modelName].maybeHandleFetchInitialData
  );
  const { loading: loadingInitialData } = useAsync(loadInitialData);

  const handleLoadStars = useStoreActions(
    (a) => a.stars.handleFetchInitialData
  );
  const { loading: loadingStars } = useAsync(handleLoadStars);

  return loadingInitialData || loadingStars;
}
