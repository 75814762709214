import { createStore } from "easy-peasy";
import { logger } from "../middleware/logger.middleware";
import { model } from "./model";
import { firebase } from "../fbase";
import { reactReduxFirebase } from "react-redux-firebase";

const isDevEnv =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const rrfConfig = {
  firebase,
  config: {},
};

const epStore = createStore(model, {
  middleware: isDevEnv ? [logger] : undefined,
  enhancers: [reactReduxFirebase(firebase, rrfConfig)],
});

export { epStore };
