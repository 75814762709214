import { makeAuthorizedGetRequestToBackend, makeUrl } from "./backendApi";
import { useAsync } from "react-use";
import _ from "lodash";

export function useEndpoint(endpoint) {
  const fn = async () => {
    const { data } = await makeAuthorizedGetRequestToBackend({
      url: makeUrl(endpoint),
    });
    return data;
  };
  let { loading, value: data } = useAsync(fn, [endpoint]);
  console.log("xxxx", { data });
  if (_.isString(data)) data = JSON.parse(data);
  return { loading, data };
}
