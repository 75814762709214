const logger = (store) => (next) => (action) => {
  console.group(action.type);
  console.log("store.getState() before", store.getState());
  console.log("Action", action);
  const result = next(action);
  console.log("store.getState() after", store.getState());
  console.groupEnd();
  return result;
};

export { logger };
