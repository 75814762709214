import React, { useEffect, useState } from "react";
import useAuthentication from "../../hooks/useAuthentication";
import useRedirect from "../../hooks/useRedirect";
import { message } from "antd";
import routes from "../../helpers/routes";
import versionedLocalStorageKey from "../../helpers/versionedLocalStorageKey";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { Button, Classes, Intent } from "@blueprintjs/core";
import { VERTICALLY_CENTER_IE11 } from "../../vertically-center-ie11";
import { HelpText } from "./help-text";
import { EmailInput, isValidEmail } from "./email-input";
import { isValidPassword, PasswordInput } from "./password-input";
import { useAuthIsReady } from "../../helpers/use-auth-is-ready";
import { ForgotPasswordBtn } from "./forgot-password-btn";
import { useStoreActions } from "../../hooks/ep";

function SubmitButton({ disabled, email, password }) {
  return (
    <Button
      data-testid="signin_submit_button"
      disabled={disabled || !isValidEmail(email) || !isValidPassword(password)}
      intent={Intent.PRIMARY}
      className={Classes.ELEVATION_2}
      type="submit"
      fill
      large
      css={VERTICALLY_CENTER_IE11}
    >
      SIGN IN
    </Button>
  );
}

export function SignInForm() {
  const { authUser } = useAuthentication();
  const handleLogin = useStoreActions(a => a.firebase.loginEmailPassword);
  const { numVisitsKey, numVisits } = useNumVisits();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const firebaseAuthIsReady = useAuthIsReady();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [activeField, setActiveField] = useState(null);

  // Redirect if user already logged in
  useRedirect(() => !!authUser, routes.DEFAULT, [authUser]);

  useEffect(() => {
    window.localStorage.setItem(numVisitsKey, (numVisits + 1).toString());
    // eslint-disable-next-line
  }, []);

  const onSubmit = async event => {
    event.preventDefault();
    setBtnDisabled(true);
    try {
      await handleLogin({ email, password });
    } catch (err) {
      message.error(err.message);
    }
    setBtnDisabled(false);
  };

  return !firebaseAuthIsReady ? (
    <p>Initializing Firebase instance...</p>
  ) : (
    <div
      css={`
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <form onSubmit={onSubmit}>
        <div
          css={`
            margin-bottom: 32px;
          `}
        >
          <EmailInput
            email={email}
            setEmail={setEmail}
            activeField={activeField}
            setActiveField={setActiveField}
          />
        </div>
        <div
          css={`
            margin-bottom: 32px;
          `}
        >
          <PasswordInput
            password={password}
            setPassword={setPassword}
            activeField={activeField}
            setActiveField={setActiveField}
          />
        </div>
        <div
          css={`
            margin-bottom: 8px;
          `}
        >
          <SubmitButton
            disabled={btnDisabled}
            email={email}
            password={password}
          />
        </div>
        <div
          css={`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <ForgotPasswordBtn email={email} />
        </div>
      </form>
      <HelpText numVisits={numVisits} />
    </div>
  );
}

function useNumVisits() {
  const numVisitsKey = versionedLocalStorageKey("login-page-number-visits");
  const numVisits = parseInt(
    window.localStorage.getItem(numVisitsKey) || "0",
    10
  );
  return { numVisitsKey, numVisits };
}
