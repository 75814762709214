import { useStoreState } from "../hooks/ep";
import { epStore } from "../store";
import _ from "lodash";
import { useDemoModelName } from "./use-demo-code-name";

export function useCustomerRow(customerId) {
  const demoCodeName = useDemoModelName();
  return useStoreState((s) => s[demoCodeName].customer2data[customerId], [
    customerId,
  ]);
}
