import { Spin } from "antd";
import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "../hooks/ep";
import { useEnsureData } from "../helpers/useEnsureData";
import { GridContext } from "./AgTable/my-ag-grid-react-context";
import AgTable from "./AgTable";
import "styled-components/macro";
import {
  useDemoCodeName,
  useDemoModelName,
} from "../helpers/use-demo-code-name";

export function BriefChurnTable() {
  const demoCodeName = useDemoCodeName();
  const anyprospectDemoCodeName = useStoreState(
    (s) => s.anyprospect.demoCodeName
  );
  const setAnyprospectDemoCodeName = useStoreActions(
    (a) => a.anyprospect.setDemoCodeName
  );
  useEffect(() => {
    setAnyprospectDemoCodeName(demoCodeName);
    // eslint-disable-next-line
  }, []);
  return useEnsureData(demoCodeName) || !anyprospectDemoCodeName ? (
    <Spin size="large" />
  ) : (
    <Inner />
  );
}

function Inner() {
  const modelName = useDemoModelName();
  const rowData = useStoreState((s) => s[modelName].rowData);
  const columnDefs = useStoreState((s) => s[modelName].initialData.columnDefs);

  return (
    <GridContext.Provider
      value={{
        tableName: "churn_risk",
      }}
    >
      <AgTable rowData={rowData} columnDefs={columnDefs} />
    </GridContext.Provider>
  );
}
