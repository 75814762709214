import React, { useState } from "react";
import styled from "styled-components/macro";
import { H4, Tab, Tabs } from "@blueprintjs/core";
import { ScoreFactors } from "./score-factors";
import { useStoreState } from "../../../hooks/ep";
import { isValidChurnScore } from "./validate-churn-score";
import { getXAxisDates, NivoTimeSeriesLine } from "./nivo-line";
import moment from "moment";
import { useDemoModelName } from "../../../helpers/use-demo-code-name";

const PopConWrapper = styled.div`
  padding: 13px 24px 23px 24px;
  //height: 500px;
  height: 440px;
  width: 500px;
  overflow-y: auto;
`;

export const PopConHeader = styled(H4)`
  margin: 0;
`;

const TAB_IDS = {
  scoreFactors: "score-factors",
  scoreHistory: "score-history"
};

export function ChuScoPopoverContent({ churnScore, customerId }) {
  const defaultTabId = getDefaultTabId(churnScore);
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <PopConWrapper>
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
      >
        {isValidChurnScore(churnScore) && (
          <Tab
            id={TAB_IDS.scoreFactors}
            title="Factors"
            panel={<ScoreFactors customerId={customerId} />}
          />
        )}
        <Tab
          id={TAB_IDS.scoreHistory}
          title="History"
          panel={<ScoreHistory customerId={customerId} />}
        />
      </Tabs>
    </PopConWrapper>
  );
}

function getDefaultTabId(churnScore) {
  return isValidChurnScore(churnScore)
    ? TAB_IDS.scoreFactors
    : TAB_IDS.scoreHistory;
}

function useScoreHistoryData(customerId) {
  const demoModelName = useDemoModelName();
  return useStoreState(
    s => s[demoModelName].scoreHistoryDataByCustomerId(customerId),
    [customerId]
  );
}

export function ScoreHistory({ customerId }) {
  const customerData = useScoreHistoryData(customerId);
  const dates = customerData.data.map(({ x }) => x);

  const data = [customerData];
  const yMin = 0;
  const yMax = 100;
  const yTicks = [0, 20, 40, 60, 80, 100];
  const yTitle = "Churn Risk";
  const xTicks = getXAxisDates(dates, 7);
  const xTitle = "Date of Prediction";
  const height = 338;
  const width = 450;
  function TooltipText({ x, y }) {
    return `${y}% on ${moment(x).format("M/D")}`;
  }

  return (
    <NivoTimeSeriesLine
      data={data}
      yMin={yMin}
      yMax={yMax}
      yTicks={yTicks}
      yTitle={yTitle}
      xTicks={xTicks}
      xTitle={xTitle}
      height={height}
      width={width}
      TooltipText={TooltipText}
    />
  );
}
