import {
  AG_TABLE_RIBBON_HEIGHT,
  LAYOUT_CONTENT_VERTICAL_PADDING,
  LAYOUT_HEADER_HEIGHT_PX
} from "../../styles/constants";

export const getGridHeight = ({ height, fullScreen }) => {
  if (height) {
    return height;
  }
  const subtractions = fullScreen
    ? ""
    : `- ${LAYOUT_HEADER_HEIGHT_PX} - 2 * ${LAYOUT_CONTENT_VERTICAL_PADDING}`;
  const ret = `calc(100vh - ${AG_TABLE_RIBBON_HEIGHT} ${subtractions})`;
  return ret;
};

export const getGridHeightWithRibbon = () =>
  `calc(100vh - ${LAYOUT_HEADER_HEIGHT_PX} - 2 * ${LAYOUT_CONTENT_VERTICAL_PADDING})`;
