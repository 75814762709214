import React from "react";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import AG_TABLE_THEMES from "../../styles/ag-table-themes";
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider } from "styled-components";

export function AgTableThemeProvider({ children }) {
  // const [isDarkMode, setIsDarkMode] = useState(
  //   () => localStorage.getItem("agTableIsDarkMode") === "true"
  // );
  // const mode = isDarkMode ? "dark" : "light";
  // const toggleDarkMode = () =>
  //   setIsDarkMode(v => {
  //     const newV = !v;
  //     localStorage.setItem("agTableIsDarkMode", JSON.stringify(newV));
  //     return newV;
  //   });
  // const theme = useMemo(
  //   () => ({
  //     mode,
  //     toggleDarkMode,
  //     ...AG_TABLE_THEMES[mode]
  //   }),
  //   [mode]
  // );
  return (
    <ThemeProvider theme={{ mode: "dark", ...AG_TABLE_THEMES["dark"] }}>
      {children}
    </ThemeProvider>
  );
}
