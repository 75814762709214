import _ from "lodash";

export function isInvalidChurnScore(churnScore) {
  return (
    _.isNull(churnScore) ||
    _.isUndefined(churnScore) ||
    _.isNaN(churnScore) ||
    churnScore < 0 ||
    churnScore > 100
  );
}

export function isValidChurnScore(churnScore) {
  return !isInvalidChurnScore(churnScore);
}
