import axios from "axios";
import { getFirebase } from "react-redux-firebase";
import _ from "lodash";
import { message } from "antd";
import useSWR from "@zeit/swr";

// const getCurrentUserIdToken = async () =>
//   getFirebase().auth().currentUser &&
//   (await getFirebase()
//     .auth()
//     .currentUser.getIdToken(false));

axios.interceptors.request.use(async function (config) {
  const currentUser = getFirebase().auth().currentUser;
  if (!currentUser) {
    console.error("No current user to get ID Token header from");
    return;
  }
  const idToken = await currentUser.getIdToken(false);
  if (!idToken) {
    console.error("Current user's ID Token is blank -- can't set header");
    return;
  }
  config.headers["X-ID-TOKEN"] = idToken;

  return config;
});

export const baseBackendUrl = () =>
  window.Cypress ||
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api"
    : "/api";

export function makeUrl(endpoint) {
  return baseBackendUrl() + "/" + _.trim(endpoint, "/");
}

// async function getIdTokenHeaders() {
//   const idToken = await getCurrentUserIdToken();
//   return {
//     "X-ID-TOKEN": idToken
//   };
// }

export async function makeAuthorizedGetRequestToBackend({ url }) {
  return await makeAuthorizedRequestToBackend({
    method: "get",
    url,
  });
}

export async function makeAuthorizedPatchRequestToBackend({
  url,
  data,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "patch",
    data,
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedPostRequestToBackend({
  url,
  data,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "post",
    data,
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedDeleteRequestToBackend({
  url,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "delete",
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedRequestToBackend({
  method,
  url,
  axiosConfig = {},
  data,
}) {
  // const axiosConfigHeaders = axiosConfig.headers || {};
  // const idTokenHeaders = await getIdTokenHeaders();
  // if (!idTokenHeaders["X-ID-TOKEN"]) {
  //   throw Error();
  // }
  // const headers = { ...axiosConfigHeaders, ...idTokenHeaders };
  return await axios.request({ url, method, data, ...axiosConfig });
}

function makeRequester(method) {
  return async (endpoint) => {
    const { data } = await makeAuthorizedRequestToBackend({
      method,
      url: makeUrl(endpoint),
    });
    return data;
  };
}

export function useAuthSWR(key, method = "get") {
  if (!key) throw Error();
  const { data, error } = useSWR(key, makeRequester(method));
  if (error) message.error({ function: "useAuthSWR", error }, 10);
  return data;
}
