import { useParams } from "react-router";
import React from "react";
import { NivoLineFromEndpoint } from "../nivo-line";

export function NapoLine({ customer_id, height, width }) {
  const endpoint = `peopleready/customer_weekly_volume_history/${customer_id}`;
  return (
    <NivoLineFromEndpoint
      endpoint={endpoint}
      height={height}
      width={width}
      enableSlices={false}
      enablePoints={false}
    />
  );
}

export function NapoPage() {
  const { customer_id } = useParams();
  return <NapoLine customer_id={customer_id} height={600} width={800} />;
}
