import React from "react";
import { FbaseAuthUserContext } from "./FbaseAuthUserContext";
import { useFirebaseState } from "./useFirebaseState";

export function withFbaseAuthentication(Component) {
  return (props) => {
    const fbState = useFirebaseState();
    const firebaseAuthIsReady =
      !fbState.isInitializing && fbState.auth.isLoaded;
    let authUser;
    if (!firebaseAuthIsReady) {
      authUser = false;
    } else if (fbState.auth.isEmpty) {
      authUser = null;
    } else {
      authUser = fbState.auth;
    }

    return (
      <FbaseAuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </FbaseAuthUserContext.Provider>
    );
  };
}
