import React from "react";
import styled from "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { Col, Layout, Menu, Row } from "antd";
import { useAuthorizedRoutes } from "../helpers/useAuthorization";
import { useLocation, useParams } from "react-router";
import {
  DOCUMENT_TITLE_AND_HEADER_TEXT,
  LAYOUT_CONTENT_HORIZONTAL_PADDING,
  LAYOUT_CONTENT_VERTICAL_PADDING,
  LAYOUT_HEADER_HEIGHT_PX,
  LAYOUT_LEFTNAV_ITEM_HEIGHT,
  LAYOUT_LEFTNAV_WIDTH_PX,
} from "../styles/constants";
import { LeftNavMenuItem, leftNavMenuItems } from "../helpers/leftNavMenuItems";
import { Colors } from "@blueprintjs/core";
import { UserSettings } from "./user-settings";
import { useFullScreenModeEnabled } from "../store/model-misc";

const { Header, Content, Sider } = Layout;

const StyledSider = styled(Sider)`
  && {
    margin: 0;
    padding: 0;
    width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    min-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    max-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    background: white;
  }
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;

  && .ant-menu-item.ant-menu-item.ant-menu-item {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
    line-height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
  }
  && .bp3-icon {
    height: 16px;
    line-height: 16px;
    vertical-align: middle;
    color: rgb(255, 255, 255, 0.65);
  }
  && .ant-menu-item:hover {
    .bp3-icon {
      color: rgb(255, 255, 255, 1);
    }
  }
`;

function LeftNavSider() {
  const location = useLocation();

  const authorizedRoutes = useAuthorizedRoutes();

  const leftNavMenuItemsComponents = leftNavMenuItems.map(LeftNavMenuItem);

  return (
    <StyledSider collapsed={true}>
      <StyledMenu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[
          location.pathname.includes("churn-dashboard")
            ? "churn-dashboard/a2mac1"
            : location.pathname,
        ]}
      >
        {leftNavMenuItemsComponents}
      </StyledMenu>
    </StyledSider>
  );
}

function MyHeader() {
  if (useFullScreenModeEnabled()) {
    return null;
  }

  return (
    <Header
      data-testid="wrapper_header"
      css={`
        height: ${LAYOUT_HEADER_HEIGHT_PX};
        vertical-align: center;
        margin: 0 !important;
        padding: 0 !important;
      `}
    >
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        css={`
          height: 100%;
          margin: 0 !important;
          padding: 0 12px 0 24px !important;
        `}
      >
        {/*<Col*/}
        {/*  css={`*/}
        {/*    padding-left: 12px;*/}
        {/*  `}*/}
        {/*>*/}
        {/*  <BP3Icon*/}
        {/*    icon="blank"*/}
        {/*    css={`*/}
        {/*      width: 40px;*/}
        {/*    `}*/}
        {/*  />*/}
        {/*</Col>*/}
        <Col>
          <img
            src="/customax-logo.png"
            height="16px"
            alt="Customax Logo"
            css={`
              cursor: pointer;
            `}
            onClick={() => window.location.reload()}
          />
        </Col>
        <Col>
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            <UserSettings />
          </div>
        </Col>
      </Row>
    </Header>
  );
}

const AppWrapper = ({
  headerText = DOCUMENT_TITLE_AND_HEADER_TEXT,
  hideLeftNav = false,
  children,
}) => {
  const fullScreenModeEnabled = useFullScreenModeEnabled();
  if (fullScreenModeEnabled) hideLeftNav = true;
  return (
    <Layout
      css={`
        min-height: 100vh;
      `}
    >
      <MyHeader headerText={headerText} />
      <Layout>
        {hideLeftNav ? null : <LeftNavSider />}
        <Layout>
          <Content
            css={`
              background-color: ${Colors.DARK_GRAY3};
              padding: ${fullScreenModeEnabled
                ? "0"
                : `${LAYOUT_CONTENT_VERTICAL_PADDING} ${LAYOUT_CONTENT_HORIZONTAL_PADDING}`};
              margin: 0;
              min-height: 280px;
              height: calc(
                100vh - ${fullScreenModeEnabled ? "0" : LAYOUT_HEADER_HEIGHT_PX}
              );
              width: ${hideLeftNav
                ? "auto"
                : `calc(100vw - ${LAYOUT_LEFTNAV_WIDTH_PX})`};
              display: flex;
              justify-content: center;
            `}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppWrapper;
