import moment from "moment";
import { Line } from "@nivo/line";
import { Classes, Tag } from "@blueprintjs/core";
import React from "react";

export function NivoTimeSeriesLine({
  data,
  yMin,
  yMax,
  yTicks,
  yTitle,
  xTicks,
  xTitle,
  height,
  width,
  TooltipText = DefaultTooltipText
}) {
  return (
    <Line
      data={data}
      theme={NIVO_LINE_THEME}
      lineWidth={2}
      height={height}
      width={width}
      margin={{ top: 10, right: 22, bottom: 48, left: 55 }}
      animate={true}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        precision: "day",
        useUTC: false
      }}
      xFormat="time:%m/%d"
      yScale={{
        type: "linear",
        stacked: false,
        min: yMin,
        max: yMax
      }}
      axisLeft={{
        tickSize: 5,
        tickValues: yTicks,
        tickPadding: 5,
        legend: yTitle,
        legendOffset: -46,
        legendPosition: "middle"
      }}
      axisBottom={{
        format: "%m/%d",
        tickValues: xTicks,
        tickSize: 5,
        tickPadding: 5,
        legend: xTitle,
        legendOffset: 41,
        legendPosition: "middle"
      }}
      useMesh={true}
      enableSlices={false}
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      innerPadding={24}
      padding={24}
      tooltip={tooltipRendererFactory(TooltipText)}
      colors={"#49aef0"}
    />
  );
}

function DefaultTooltipText({ x, y }) {
  return `${y} on ${moment(x).format("M/D")}`;
}

function tooltipRendererFactory(TooltipText) {
  return ({
    point: {
      data: { x, y }
    }
  }) => (
    <Tag
      large
      className={`${Classes.ELEVATION_1}`}
      css={`
        color: #fff !important;
        background-color: rgba(0, 25, 43, 0.8) !important;
      `}
    >
      <TooltipText x={x} y={y} />
    </Tag>
  );
}

export function getXAxisDates(dates, desiredNumXAxisDates) {
  const xStride = Math.round(dates.length / (desiredNumXAxisDates - 1));

  const ret = [];

  for (let i = 0; i < dates.length; i = i + xStride) {
    ret.push(dates[i]);
  }

  if (ret[ret.length - 1] !== dates[dates.length - 1]) {
    ret.push(dates[dates.length - 1]);
  }

  return ret.map(d =>
    moment(d)
      .add(8, "hours")
      .toDate()
  );
}

const NIVO_LINE_THEME = {
  background: "transparent",
  fontFamily: "sans-serif",
  fontSize: 14,
  textColor: "#fff",
  axis: {
    domain: {
      line: {
        stroke: "transparent",
        strokeWidth: 1
      }
    },
    ticks: {
      line: {
        stroke: "#b9b9b9",
        strokeWidth: 1
      },
      text: {
        fontSize: 14
      }
    },
    legend: {
      text: {
        fontSize: 16
      }
    }
  },
  grid: {
    line: {
      stroke: "#dddddd",
      strokeWidth: 1
    }
  },
  legends: {
    text: {
      fill: "#b9b9b9"
    }
  },
  labels: {
    text: {}
  },
  markers: {
    lineColor: "#000000",
    lineStrokeWidth: 1,
    text: {}
  },
  dots: {
    text: {}
  },
  tooltip: {
    container: {
      background: "rgba(26,38,51,0.8)",
      color: "inherit",
      fontSize: "inherit",
      borderRadius: "2px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
      padding: "5px 9px"
    },
    basic: {
      whiteSpace: "pre",
      display: "flex",
      alignItems: "center"
    },
    table: {},
    tableCell: {
      padding: "3px 5px"
    }
  },
  crosshair: {
    line: {
      stroke: "#e1e1e1",
      strokeWidth: 1,
      strokeOpacity: 0.75,
      strokeDasharray: "6 6"
    }
  },
  annotations: {
    text: {
      fontSize: 14,
      outlineWidth: 2,
      outlineColor: "#000000"
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff"
    },
    outline: {
      fill: "none",
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff"
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff"
    }
  }
};
