import React from "react";
import { Spin } from "antd";
import AppWrapper from "./components/AppWrapper";
import { BrowserRouter } from "react-router-dom";
import { Switch } from "react-router";
import { withFbaseAuthentication } from "./fbase";
import { useTitle } from "react-use";
import { useMe } from "./hooks/useMe";
import { AllRoutes } from "./pages_routes";
import { DOCUMENT_TITLE_AND_HEADER_TEXT } from "./styles/constants";

function App() {
  useTitle(DOCUMENT_TITLE_AND_HEADER_TEXT);

  const me = useMe();

  if (me.loading) {
    return (
      <AppWrapper headerText="" hideLeftNav={true}>
        <Spin size="large" />
      </AppWrapper>
    );
  } else {
    return (
      <div className="bp3-dark">
        <BrowserRouter>
          <Switch>{AllRoutes}</Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default withFbaseAuthentication(App);
