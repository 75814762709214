import { useBoolean } from "../../helpers/useBoolean";
import { Icon, message } from "antd";
import React from "react";
import styled from "styled-components/macro";
import "styled-components/macro";
import {
  makeAuthorizedDeleteRequestToBackend,
  makeAuthorizedPostRequestToBackend,
  makeUrl
} from "../../helpers/backendApi"; // DO NOT REMOVE. Necessary for using the css={`...`} prop

const useStarUnstarActions = customerId1 => ({
  unstarCustomer: async customerId2 =>
    await makeAuthorizedDeleteRequestToBackend({
      url: makeUrl(`stars/${customerId1 || customerId2}`),
      axiosConfig: undefined
    }),
  starCustomer: async customerId2 =>
    await makeAuthorizedPostRequestToBackend({
      url: makeUrl(`stars/${customerId1 || customerId2}`),
      data: {},
      axiosConfig: undefined
    })
});

const YellowIcon = styled(Icon)`
  font-size: 1rem;
  vertical-align: middle;
`;

export const DivToPutIconInMiddleOfCell = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`;

export function StarCellRenderer({ value, data, api }) {
  const customerId = data.customer_id;
  // noinspection JSUnresolvedVariable
  const customerName = data.customer_name;

  // const starred = useIsStarred(customerId);
  const starred = useBoolean(value);

  const { starCustomer, unstarCustomer } = useStarUnstarActions(customerId);

  const onClick = async ev => {
    ev.preventDefault();
    if (!starred.value) {
      try {
        starred.setTrue();
        await starCustomer(customerId);
        message.success(`Successfully starred ${customerName}`, 1);
        api.updateRowData({ update: [{ ...data, starred: 1 }] });
      } catch (e) {
        starred.setFalse();
        message.error(`Failed to star ${customerName}`);
      }
    } else {
      try {
        starred.setFalse();
        await unstarCustomer(customerId);
        message.success(`Successfully un-starred ${customerName}`, 1);
        api.updateRowData({ update: [{ ...data, starred: 0 }] });
      } catch (e) {
        starred.setTrue();
        message.error(`Failed to un-star ${customerName}`);
      }
    }
  };

  return (
    <DivToPutIconInMiddleOfCell>
      <YellowIcon
        type="star"
        theme={starred.value ? "filled" : "outlined"}
        css={`
          & {
            color: ${starred.value
              ? "rgba(242,184,36, 0.8)"
              : "rgba(242,184,36, 0.4)"};
          }
          &:hover {
            color: ${starred.value
              ? "rgba(242,184,36, 0.4)"
              : "rgba(242,184,36, 0.8)"};
          }
        `}
        onClick={onClick}
      />
    </DivToPutIconInMiddleOfCell>
  );
}
