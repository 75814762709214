import React, { useMemo } from "react";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import CategoryColumnFilter from "./categoryColumnFilter";
import { StarCellRenderer } from "./star-cell-renderer";
import { useGridContext } from "./my-ag-grid-react-context";
import { ActionButtonCellRenderer } from "../table-actions/action-cell-renderer";
import { RecommendedActionButtonCellRenderer } from "../table-actions/recommended-action-cell-renderer";
import { PhoneActionButtonCellRenderer } from "../table-actions/phone-action-cell-renderer";
import { ChurnScoreCellRenderer } from "./churn-score-cell/cell-renderer";
import _ from "lodash";
import { WeeklyVolumeCellRenderer } from "./wv-cell/cell-renderer";

const USE_BIG_HEADERS = false;

const nfInt = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const nfFloat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const FALSEY_VALUES = new Set([false, "false", "False", "No", "no"]);
const TRUTHY_VALUES = new Set([true, "true", "True", "Yes", "yes"]);

function LinkedTextCellRenderer({ value: text, data, colDef }) {
  const link = data[colDef.__linkField];
  console.log(text, link, colDef);
  // noinspection JSConstructorReturnsPrimitive
  if (!link) {
    return <span>{text}</span>;
  } else {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={inferMailtoLinks(link)} target="_blank">
        {text}
      </a>
    );
  }
}

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function inferMailtoLinks(url) {
  if (url && validateEmail(url)) {
    return `mailto:${url}`;
  } else {
    return url;
  }
}

function UrlCellRenderer({ value: url }) {
  // noinspection JSConstructorReturnsPrimitive
  return !url ? null : (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={inferMailtoLinks(url)} target="_blank">
      Link
    </a>
  );
}

function timestampComparator(filterLocalValue, cellValue) {
  // Assume dates are stored as iso
  const cellDate = cellValue && new Date(cellValue);
  const filterLocalDate = filterLocalValue && new Date(filterLocalValue);

  if (!cellDate && !filterLocalDate) {
    return 0;
  } else if (!filterLocalDate) {
    return -1;
  } else if (!cellValue) {
    return 1;
  } else if (filterLocalDate < cellDate) {
    return -1;
  } else if (filterLocalDate > cellDate) {
    return 1;
  } else {
    return 0;
  }
}

export function dateVF({ value }) {
  let ret = null;
  if (value) {
    const dateString = new Date(value).toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric"
    });
    if (dateString !== "Invalid Date") {
      ret = dateString;
    }
  }
  return ret;
}

export function timestampVF({ value }) {
  let ret = null;
  if (value) {
    const dateString = new Date(value).toLocaleString();
    if (dateString !== "Invalid Date") {
      ret = dateString;
    }
  }
  return ret;
}

export function booleanVF(params) {
  if (params.value === undefined || params.value === null) {
    return "";
  } else if (FALSEY_VALUES.has(params.value)) {
    return false;
  } else if (TRUTHY_VALUES.has(params.value)) {
    return true;
  } else {
    throw Error(
      `booleanFormatter cannot format params with params.value=${params.value}`
    );
  }
}

export function moneyVF(params) {
  return "$" + floatVF(params);
}
export function floatVF(params) {
  // noinspection JSUnresolvedFunction
  return nfFloat.format(_.isNumber(params) ? params : params.value);
}

export function integerVF(params) {
  // noinspection JSUnresolvedFunction
  return nfInt.format(_.isNumber(params) ? params : params.value);
}

// noinspection JSUnresolvedFunction
const DEFAULT_GRID_OPTIONS = {
  cellStyle: { "justify-content": "flex-end", "text-align": "right" },
  columnTypes: {
    dateColumn: {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: timestampComparator
      },
      comparator: timestampComparator,
      valueFormatter: dateVF
    },
    timestampColumn: {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: timestampComparator
      },
      comparator: timestampComparator,
      valueFormatter: timestampVF
    },
    booleanColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: booleanVF
    },
    integerColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: integerVF
    },
    floatColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: floatVF
    },
    moneyColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: moneyVF
    },
    textColumn: {
      filter: "agTextColumnFilter"
    },
    urlColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "urlRenderer"
    },
    linkedTextColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "linkedTextRenderer"
    },
    categoryColumn: {
      filter: "categoryFilterer"
    }
  },
  frameworkComponents: {
    urlRenderer: UrlCellRenderer,
    linkedTextRenderer: LinkedTextCellRenderer,
    categoryFilterer: CategoryColumnFilter,
    churnScoreCellRenderer: ChurnScoreCellRenderer,
    starCellRenderer: StarCellRenderer,
    actionButtonCellRenderer: ActionButtonCellRenderer,
    recommendedActionButtonCellRenderer: RecommendedActionButtonCellRenderer,
    phoneActionButtonCellRenderer: PhoneActionButtonCellRenderer,
    weeklyVolumeCellRenderer: WeeklyVolumeCellRenderer
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    sortingOrder: ["desc", "asc", null],
    filter: true,
    suppressAutoSizeColumns: false,
    filterParams: {
      applyButton: true,
      clearButton: true,
      debounceMs: 200
    },
    maxWidth: USE_BIG_HEADERS ? 250 : undefined
  },
  deltaRowDataMode: true,
  getRowNodeId: data => data.id,
  headerHeight: 48,
  rowHeight: 48,
  enableCellTextSelection: true
};

function useGridOptions({ scrollbarWidth, ...onEvents }) {
  const eventListeners = Object.values(onEvents);
  const context = useGridContext();
  return useMemo(
    () => ({
      scrollbarWidth,
      ...onEvents,
      ...DEFAULT_GRID_OPTIONS,
      context
    }),
    [eventListeners, scrollbarWidth, context]
  );
}

export { useGridOptions };
