import { action, createContextStore } from "easy-peasy";

export const SearchBoxStore = createContextStore({
  text: "",
  setText: action((state, newText) => {
    if (newText !== state.text) {
      state.text = newText;
    }
  })
});

export function useSearchBoxText() {
  return SearchBoxStore.useStoreState(s => s.text);
}

export function useSetSearchBoxText() {
  return SearchBoxStore.useStoreActions(a => a.setText);
}
