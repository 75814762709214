import { message } from "antd";
import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";
import {
  makeAuthorizedPatchRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { actionFunctions } from "./commonActions";
import { StoreModel } from "./model";
import { fsIdentify, safeIdentify } from "../fstory";
import { Injections } from "./store-injections";
import { UserRole } from "../helpers/user-role";

// The interface representing our Todos model
export interface MeModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: {
    id: number;
    role: UserRole;
    email: string;
    timezone: string;
    first_name: string;
    last_name: string;
  } | null;
  fullName: Computed<MeModel, string>;
  initialDataReceived: boolean;
  synchronizeWithFirebaseAuth: Thunk<MeModel, any, Injections, StoreModel>;
  clear: Action<MeModel, void>;
  handleUpdateTimezone: Thunk<MeModel, any, Injections, StoreModel>;
  receiveTimezone: Action<MeModel, any>;
  receiveInitialData: Action<MeModel, any>;
  maybeHandleFetchInitialData: Thunk<MeModel, any, Injections, StoreModel>;
}

// @ts-ignore
const meModel: MeModel = {
  INITIAL_DATA_ENDPOINT: "me",
  NAME: "me",
  initialData: null,
  fullName: computed(
    (s) =>
      s.initialData && `${s.initialData.first_name} ${s.initialData.last_name}`
  ),
  synchronizeWithFirebaseAuth: thunk(
    async (
      actions,
      { userIsSignedIn, userMustSignIn },
      { getState, dispatch, getStoreState }
    ) => {
      const { initialDataReceived } = getState();
      if (userMustSignIn) {
        actions.clear();
      } else if (userIsSignedIn && !initialDataReceived) {
        const {
          email,
          displayName,
        } = getStoreState().firebase.red.firebase.auth;

        fsIdentify({ email, displayName });

        try {
          await dispatch.me.maybeHandleFetchInitialData();
        } catch (err) {
          message.error(
            `Your user account (${email}) is missing or inactive. ` +
              `Please email team@saxecap.com and for help. ${err}`
          );
          throw err;
        }
      }
    }
  ),
  clear: action((state) => {
    state.initialData = null;
    state.initialDataReceived = false;

    safeIdentify(false);
  }),
  handleUpdateTimezone: thunk(async (actions, payload, { getState }) => {
    const url = makeUrl("me");
    const data = { timezone: payload };
    const resp = await makeAuthorizedPatchRequestToBackend({
      url,
      data,
      axiosConfig: undefined,
    });
    actions.receiveTimezone(resp.data);
  }),
  receiveTimezone: action((state, payload) => {
    state.initialData.timezone = payload.timezone;
  }),
  receiveInitialData: action(actionFunctions().receiveInitialData),
};

export default meModel;
