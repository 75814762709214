import React, { useCallback } from "react";
import { StyledFormGroup } from "../styled-form-group";
import { Classes, InputGroup } from "@blueprintjs/core";
import "styled-components/macro";

export function EmailInput({ email, setEmail, activeField, setActiveField }) {
  const onEmailInputChange = useCallback(
    (ev) => {
      ev.preventDefault();
      setEmail(ev.target.value);
    },
    [setEmail]
  );

  return (
    <StyledFormGroup labelFor="email" label="Email" activeField={activeField}>
      <InputGroup
        data-testid="signin_email_input"
        name="email"
        className={Classes.ELEVATION_2}
        type="email"
        fill={false}
        large={true}
        leftIcon="envelope"
        value={email}
        onChange={onEmailInputChange}
        placeholder="Enter your email.."
        onFocus={() => setActiveField("email")}
      />
    </StyledFormGroup>
  );
}

const VALID_EMAIL_PATT = /^.+?@.+?\.\w{2,3}$/;

export function isValidEmail(email) {
  return VALID_EMAIL_PATT.test(email);
}
