import _ from "lodash";

export function getSegmentId(seg) {
  return [seg.t0, seg.t1].filter(v => !!v).join("__");
}

export function getSegmentName(seg) {
  const segid = _.isString(seg) ? seg : getSegmentId(seg);
  return SEG_NAMES[segid];
}

export function getAxisName(axis) {
  let axisid = _.isString(axis) ? axis : axis.id;
  if (axisid === "") {
    axisid = "none";
  }
  return AXE_NAMES[axisid];
}

export const isRealAxis = axis => axis.id !== "none";
export const AXE_NAMES = {
  none: "None",
  VerticalSegmentation: "Vertical",
  RevenueSegmentation: "Revenue",
  BillingState: "State",
  CustomerStatus: "Status",
  CreditStatus: "Credit",
  ServiceSegmentation: "Service"
};
export const SEG_NAMES = {
  customers: "Customers",
  true_churners: "True Churners",
  non_true_churners: "Non True-Churners"
};
export const SEGMENT_HIERARCHY = [
  {
    parent: { t0: "customers" },
    children: [{ t0: "true_churners" }, { t0: "non_true_churners" }]
  }
];

export function giveNames({ seg1, seg2, axis, ...rest }) {
  return {
    seg1: seg1.name ? seg1 : { ...seg1, name: getSegmentName(seg1) },
    seg2: seg2.name ? seg2 : { ...seg2, name: getSegmentName(seg2) },
    axis: axis.name ? axis : { ...axis, name: getAxisName(axis) },
    ...rest
  };
}

export function giveSize(t) {
  return { width: 600, height: 400, ...t };
}

export const NULL_AXIS = { id: "none" };
export const DEFAULT_TILES = [
  {
    seg1: { t0: "customers" },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "VerticalSegmentation" },
    seg1: {
      t0: "customers"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "VerticalSegmentation" },
    seg1: {
      t0: "non_true_churners"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "RevenueSegmentation" },
    seg1: {
      t0: "customers"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "RevenueSegmentation" },
    seg1: {
      t0: "non_true_churners"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "CreditStatus" },
    seg1: {
      t0: "customers"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "CreditStatus" },
    seg1: {
      t0: "non_true_churners"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "ServiceSegmentation" },
    seg1: {
      t0: "customers"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "ServiceSegmentation" },
    seg1: {
      t0: "non_true_churners"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "CustomerStatus" },
    seg1: {
      t0: "customers"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "CustomerStatus" },
    seg1: {
      t0: "non_true_churners"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "BillingState" },
    seg1: {
      t0: "customers"
    },
    seg2: { t0: "true_churners" }
  },
  {
    axis: { id: "BillingState" },
    seg1: {
      t0: "non_true_churners"
    },
    seg2: { t0: "true_churners" }
  }
]
  .map((t, id) => ({ id, axis: t.axis ?? NULL_AXIS, ...t }))
  .map(giveNames)
  .map(giveSize);
