import { useMe, useMeData } from "../hooks/useMe";

export function useUserTimezone() {
  const { loading } = useMe();
  const meData = useMeData();
  if (loading || !meData || !meData.timezone) {
    return null;
  } else {
    return meData.timezone;
  }
}
