import { ResponsivePie } from "@nivo/pie";
import React from "react";
import "styled-components/macro";
import { NIVO_LINE_THEME } from "../AgTable/nivo-line-theme";
import _ from "lodash";
import { integerVF } from "../AgTable/gridOptions";
import { Classes, Tag } from "@blueprintjs/core";

export function NivoPie({ width, height, data }) {
  console.log("yyy", { data });
  const total = _.sumBy(data, "value");
  const formatSliceLabel = ({ value }) => {
    // return `${pct}%`;
    return `${integerVF(value)}`;
    // return `${integerVF(value)} (${pct}%)`;
  };
  const margin = { top: 40, right: 160, bottom: 40, left: 160 };
  return (
    <div
      css={`
        text-align: center;
        position: relative;
        width: ${width}px;
        height: ${height}px;
      `}
    >
      <ResponsivePie
        // width={width}
        // height={height}
        data={data}
        theme={NIVO_LINE_THEME}
        margin={margin}
        innerRadius={0.5}
        // startAngle={90}
        // endAngle={360 + 90}
        padAngle={2}
        cornerRadius={3}
        colors={{ scheme: "category10" }}
        borderWidth={0}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#fff"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#fff"
        sliceLabel={formatSliceLabel}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={tooltipRendererFactory(total)}
        // sortByValue={true}
        // legends={LEGENDS}
      />
      <div
        css={`
          position: absolute;
          top: 0;
          right: ${margin.right}px;
          bottom: 0;
          left: ${margin.left}px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #ffffff;
          // background: #FFFFFF33
          text-align: center;
          // This is important to preserve the chart interactivity
          pointer-events: none;
        `}
      >
        <span>{integerVF(total)}</span>
      </div>
    </div>
  );
}

function tooltipRendererFactory(total) {
  return ({ id, value }) => {
    const pct = ((100 * value) / total).toFixed(1);
    return (
      <Tag
        large
        className={`${Classes.ELEVATION_1}`}
        css={`
          color: #fff !important;
          background-color: rgba(0, 25, 43, 0.8) !important;
        `}
      >
        {`${id}: ${integerVF(value)} (${pct}%)`}
      </Tag>
    );
  };
}
