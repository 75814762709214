import _ from "lodash";
import "moment-timezone";
import Moment from "moment";
import { extendMoment } from "moment-range";

// @ts-ignore
export const moment = extendMoment(Moment);

export interface Point {
  x: any;
  y: any;
}

export interface RowDatum {
  customer_id: string;

  [index: string]: any;
}

export interface ActionEmailTemplate {
  action_name: string;
  subject: string;
  body: string;
}

export interface InitialData {
  rowData: RowDatum[];
  scoreHistories: { id: string; data: Point[] }[];
  columnDefs: object[];
  emailTemplates: ActionEmailTemplate[];
}

export function getScoreHistories(rowData) {
  return rowData.map((row) => {
    const churnScore = row["y_churn_risk_score"];
    const trend = row["yTrend"];
    const ret = getArtificialScoreHistoryData(churnScore, trend);

    const data = _.sortBy(
      Object.entries(ret).map(([x, y]) => ({ x, y })),
      "x"
    );
    return { id: row.customer_id, data };
  });
}

const CUSTOMAX_LAUNCH_DATE = "2019-11-11";

function getAllScoreHistoryDates() {
  const start = moment(CUSTOMAX_LAUNCH_DATE, "YYYY-MM-DD"); //.tz("America/Los_Angeles");
  const end = moment(); //.tz("America/Los_Angeles");
  const range = moment.range(start, end);
  return Array.from(range.by("day")).map((m) => m.format("YYYY-MM-DD"));
}

function getArtificialScoreHistoryData(finalChurnScore, trend) {
  let ret = {};
  let step;
  let curr = finalChurnScore;

  _.sortBy(getAllScoreHistoryDates(), (v) => v)
    .reverse()
    .forEach((date) => {
      ret[date] = curr;

      if (getRandomInt({ min: 0, max: 1 })) {
        step = 0; // 50% chance of no change
      } else if (trend === "up") {
        step = getRandomInt({ min: -2, max: 3 });
      } else if (trend === "down") {
        step = getRandomInt({ min: -3, max: 2 });
      } else {
        step = getRandomInt({ min: -1, max: 1 });
      }

      curr = Math.max(0, Math.min(100, curr - step));
    });
  return ret;
}

function getRandomInt({ min = 0, max }) {
  return Math.round(min + Math.random() * Math.floor(max - min));
}

export function initialInitialData() {
  return {
    rowData: [],
    scoreHistories: [],
    columnDefs: [],
    emailTemplates: [],
  };
}

console.log(initialInitialData());
